import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Image, Upload } from 'antd';
import { getToken,request } from "@/utils"

const token = getToken()
// const getBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });
const Test6 = () => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);




  // const handlePreview = async (file) => {
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj);
  //   }
  //   setPreviewImage(file.url || file.preview);
  //   setPreviewOpen(true);
  // };

  const onRemove = async (e) => {
    await request.post('/p_upfile_d', e)

  }


  const handleChange = ({ fileList: newFileList }) => {

    if (newFileList[0] !== undefined && newFileList[0].status === 'done') {

      newFileList = newFileList.map((file) => {


        if (file.response) {
          if (file.response.return_code === 'success') {
            file.uid = file.response.fbody[0].uid;
            file.name = file.response.fbody[0].name;
            file.status = file.response.fbody[0].status;
            file.url = file.response.fbody[0].url;

            //response
          }
          if (file.response.return_code === 'F') { file.status = "" }
          //这里可以更新
        }
        delete file.response; delete file.originFileObj; delete file.lastModified;
        delete file.lastModifiedDate;
        delete file.percent;
        delete file.size;
        delete file.xhr;
        return file;
      });
    }

    setFileList(newFileList)
  }
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );



  const props = {
    action: 'http://api.ddspp.com:30123/p_upfile',
    onChange: handleChange,
    // multiple: true,
    fileList: fileList,
    showUploadList: true,
    listType: 'picture-card',
    // handlePreview: handlePreview,
    headers: { Authorization: `{"Bearer_ddspp":"${token}"}` },
    onRemove: onRemove
  };


  return (
    <>
      <Upload
        {...props}
      >
        {fileList.length >= 5 ? null : uploadButton}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{
            display: 'none',
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </>
  );
};
export default Test6