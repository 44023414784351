import React from 'react';
import './index.scss';
const Ffoot=()=>{
   
   
   return <div className='foot'>
    <hr/>
<span className='footcopyr'>郑州胜聪电子科技有限公司©2024 ★ <a target='blank' href='https://beian.miit.gov.cn/' >豫ICP备13017074号</a></span>

</div>
  


}
    
    export default Ffoot