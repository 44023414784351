//和用户相关的状态管理
import { message } from 'antd';
import { createSlice } from '@reduxjs/toolkit'
import { request, setToken as _setToken, getToken, setUserInfo as _setUserInfo } from '@/utils'

import md5 from "js-md5";

const userStore = createSlice({
    name: "user",
    initialState: {
        token: getToken() || '',
        username: {}

    },
    reducers: {
        setToken(state, action) {
            state.token = action.payload
            //localStorage 也存一份
            _setToken(action.payload)

        },
        setUserInfo(state, action) {
            state.username = action.payload
            _setUserInfo(action.payload)
        }
    }

})


const { setToken, setUserInfo } = userStore.actions
//获取reducer 函数
const userReducer = userStore.reducer
//异步方法 完成登录 获取token
const fetchLogin = (loginFrom) => {
    
  
    const md5psw = md5(loginFrom['password']);
    const o2 = { password: md5psw };
    const loginFrom2 = Object.assign(loginFrom, o2);

    // console.log("处理的数值：" + JSON.stringify(loginFrom2));
    return async (dispatch) => {
        const res = await request.post('/login',loginFrom2 )

        if (res.return_code === "F") { message.error('用户名或密码错误，登录失败') }
        if (res.return_code === "success") {
            localStorage.setItem('scusername', res.username)
            dispatch(setToken(res.token))
            message.success('登录成功')
        }
    }

}

const fetchUserInfo = () => {
    return async (dispatch) => {
        const resq = await request.get('/userinfo')
        dispatch(setUserInfo(resq.username))
    }

}
export { fetchLogin, fetchUserInfo, setToken }
export default userReducer