import React from 'react';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Card } from 'antd';
const { Meta } = Card;
const Test5 = () => (
  <Card
    style={{
      width: 300,
    }}
    cover={
      <img
        alt="example"
         src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
        // src="http://api.ddspp.com:30123/test/t5.php"
      />
    }
    actions={[
      <SettingOutlined key="setting" />,
      <EditOutlined key="edit" />,
      <EllipsisOutlined key="ellipsis" />,
    ]}
  >
    
    <Meta
      avatar={<Avatar src="http://api.ddspp.com:30123/test/t5.php" />}
      title="Card title"
      description="This is the description"
    />
  </Card>

  


// http://api.ddspp.com:30123/test/t5.php
);
export default Test5