/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect,useRef } from 'react';
import { Form, Input, Table, ConfigProvider, Drawer,  Button, Select, message,Tag,Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { request } from '@/utils'
import { useSearchParams } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import './index.scss'
import zhCN from 'antd/locale/zh_CN';












const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled User',
    // Column configuration not to be checked
    name: record.name,
  }),
};


const Test = () => {
  const [urlp, setSearch] = useSearchParams()
  // console.log("urlc:" + urlp.get('t') )
  
   const [bangm, setbangm] = useState([]);
  useEffect(() => {
    async function getau() {
      const res = await request.post('/pms_list')
       setbangm(res.rescode)
    }
  
    getau()
  
  }, [urlp])
  

 

  const [openadcp, setOpenadcp] = useState(false);
  const showDrawer = () => {
    setOpenadcp(true);
  };
  const onClose = () => {
    setOpenadcp(false);
  };
  const [adcp] = Form.useForm();
  const onFinishadcp = async (v) => {
    const resadcp = await request.post('/ad_pms', v)

    if (resadcp.return_code === "success") {
      setSearch('t='+resadcp.times)
      message.success(resadcp.return_msg)//提示
      adcp.setFieldsValue({ name: '', tags: [] });//清除输入框内容
      setOpenadcp(false);//关闭
  // 这里还需要将返回的json 添加到表格json  接口文档也需要适当返回添加产品的json 

    }
    if (resadcp.return_code === "F") {
      message.error(resadcp.return_msg)
    }


  }

 const ppth=(e)=>{
  console.log(e)
 }
 
 const [searchText, setSearchText] = useState('');
 const [searchedColumn, setSearchedColumn] = useState('');
 const searchInput = useRef(null);
 const handleSearch = (selectedKeys, confirm, dataIndex) => {
   confirm();
   setSearchText(selectedKeys[0]);
   setSearchedColumn(dataIndex);
 };
 const handleReset = (clearFilters) => {
   clearFilters();
   setSearchText('');
 };
 const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`检索内容`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          检索
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
          }}
        >
          重置
        </Button>
       
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          关闭
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{
        color: filtered ? '#1677ff' : undefined,
      }}
    />
  ),
  onFilter: (value, record) =>
    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100);
    }
  },
  render: (text) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
});




 const columns = [
  
  {
    title: '产品编号',
    dataIndex: 'key',
    key: 'key',
    ...getColumnSearchProps('key'),
    sorter: (a, b) => a.key - b.key,
      sortDirections: ['descend', 'ascend'],
  },
  {
    title: '名称',
    dataIndex: 'pname',
    key: 'pname',
    ...getColumnSearchProps('pname'),
  },
  {
    title: '标签',
    key: 'tags',
    ...getColumnSearchProps('tags'),
    dataIndex: 'tags',
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          let color = tag.length > 5 ? 'geekblue' : 'green';
          if (tag === 'loser') {
            color = 'volcano';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
    
  },
  {
    title: <Button type="link" onClick={showDrawer}>+ 产品新增</Button>,
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
      <span onClick={() => ppth(record)}>操作1
      {/* {record.pname} */}
      </span>
      <a onClick={() => ppth(record)}>操作2
      {/* {record.pname} */}
      </a>
      
    </Space>
    ),
  },
];
  

  

 









  const [form] = Form.useForm();
  return (<ConfigProvider locale={zhCN} >

    <div 
    // style={containerStyle} 
    >

      <Form form={form} component={false}>

      <Table rowSelection={   {type:"checkbox",...rowSelection,
          }} columns={columns} dataSource={bangm} />

      </Form>


      <Drawer
        title="增加产品"
        placement="bottom"
        closable={false}
        onClose={onClose}
        open={openadcp}
        getContainer={false}
      >


        <Form
          name="adcp"
          labelCol={{
            flex: '110px',
          }}
          labelAlign="left"
          labelWrap
          wrapperCol={{
            flex: 1,
          }}
          onFinish={onFinishadcp}
          form={adcp}
        >
          <Form.Item
            label="产品名称"
            name="pname"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="产品标签"
            name="tags"
            rules={[
              {
                required: true,
              },
            ]}
          >

            <Select
              mode="tags"
              style={{
                width: '100%',
              }}
              tokenSeparators={[',']}
              placeholder='产品标签，方便查询，不对外展示'

            />
          </Form.Item>

          <Form.Item >
            <Button type="primary" htmlType="submit">
              提 交
            </Button>
          </Form.Item>
        </Form>







      </Drawer>
    </div>

    
  </ConfigProvider>

  );
};
export default Test;