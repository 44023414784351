import React, { useState } from 'react';
import {  Upload } from 'antd';
import { getToken } from "@/utils"

const token=getToken()
const Test7 = () => {
  const [fileList, setFileList] = useState([
    {
      uid: '-1',
      name: 'xxx.png',
      status: 'done',
      url: 'http://www.baidu.com/xxx.png',
    },
  ]);

  const onRemove  =(e)=>{

    console.log(e)
  }
  const handleChange = (info) => {
    
    let newFileList = [...info.fileList];


    
    newFileList = newFileList.slice(-5);

    // 2. Read from response and show file link
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
        file.return_msg = file.response.return_msg;
        console.log("info=="+JSON.stringify(file.return_msg))
      }
      return file;
    });
    setFileList(newFileList);
  };
  const props = {
    action: 'http://api.ddspp.com:30123/p_upfile',
    onChange: handleChange,
    multiple: true,
    listType:'picture-card',
    headers:{Authorization:`{"Bearer_ddspp":"${token}"}`},
    onRemove:onRemove  
  };
  return (<> <Upload {...props} fileList={fileList}>
    Upload
    {/* <Button icon={<UploadOutlined />}>Upload</Button> */}
    
  </Upload>

  
  </>
   
  );
};
export default Test7