//列表显示所有物料   //KEY对于组件映射关系
import { Button, Input, Tag, Timeline, Collapse, QRCode, Carousel, Image,Table } from 'antd';
import { DashOutlined, HistoryOutlined, QrcodeOutlined, RightOutlined,TableOutlined } from '@ant-design/icons'
function createEditorConfig() {
    const componentList = [];
    const componentMap = {}
    return {
        componentList,
        componentMap,
        register: (component) => {
            componentList.push(component);
            componentMap[component.key] = component
        }
    }
}
export let registerConfig = createEditorConfig();
// const createInputProp = (label) => ({ type: 'input', label });   // 工厂函数
// const createColorProp = (label) => ({ type: 'color', label });
// const createSelectProp = (label, options) => ({ type: 'select', label, options });




registerConfig.register({
    label: '文本',
    preview: () => <Tag color="processing">预览文本</Tag>,
    render: () => <span >渲染文本</span>,
    // render: ({ props }) => <span style={{ color: props.color, fontSize: props.size }}>{props.text || '渲染文本'}</span>,
    key: 'text',
    // props: {
    //     text: createInputProp('文本内容'),
    //     color: createColorProp('字体颜色'),
    //     size: createSelectProp('字体大小', [
    //         { label: '14px', value: '14px' },
    //         { label: '20px', value: '20px' },
    //         { label: '24px', value: '24px' },
    //     ])
    // }
})

registerConfig.register({
    label: '按钮',
    resize: {
        width: true, height: true   //更改大小
    },
    preview: () => <Button type="primary">预览按钮</Button>,
    render: () => <Button  >渲染按钮</Button>,
    //render: ({ props, size }) => <Button style={{ height: size.height + 'px', width: size.width + 'px' }} type={props.type} size={props.size} >{props.text || '渲染按钮'}</Button>,
    key: 'button',
    // props: {
    //     text: createInputProp('按钮内容'),
    //     type: createSelectProp('按钮类型', [
    //         { label: '基础', value: "primary" },
    //         { label: '成功', value: "success" },
    //         { label: '警告', value: "warning" },
    //         { label: '危险', value: "danger" },
    //         { label: '文本', value: "text" },
    //     ]),
    //     size: createSelectProp('按钮大小', [
    //         { label: '默认', value: "" },
    //         { label: '中等', value: "large" },
    //         { label: '小', value: "small" },
    //     ])
    // }
})

registerConfig.register({
    label: '输入框',
    resize: {
        width: true,   //更改输入框横向大小
    },
    preview: () => <Input placeholder="预览输入框"></Input>,
    render: () => <Input placeholder="渲染输入框"  style={{width:"80%"}}></Input>,
    // render: ({ model, size }) => <Input placeholder="渲染输入框" {...model.default} style={{ width: size.width + 'px' }}></Input>,
    key: 'input',
    // model: {   //{default:'username'}
    //     default: '绑定字段'

    // }

})


registerConfig.register({
    label: ' ',
    preview: () => <Button
        type="primary"
        icon={<HistoryOutlined />}
    >
        时间线
    </Button>,
    render: () => <Timeline
    style={{paddingLeft:"20px"}}
        items={[
            {
                children: 'Create a services site 2015-09-01',
            },
            {
                children: 'Solve initial network problems 2015-09-01',
            }
        ]}
    />,
    // render: ({ model, size }) => <Input placeholder="渲染输入框" {...model.default} style={{ width: size.width + 'px' }}></Input>,
    key: 'timeline',
    // model: {   //{default:'username'}
    //     default: '绑定字段'

    // }

})



registerConfig.register({
    label: ' ',
    preview: () =>
        <Button
            type="primary"
            icon={<RightOutlined />}
        >
            折叠面板
        </Button>
    ,
    render: () => <Collapse
        defaultActiveKey={['1']}
        size="small"
        items={[
            {
                key: '1',
                label: '折叠面板',
                children: <p>5232222222222222222222</p>,
            },
        ]}
    />,
    // render: ({ model, size }) => <Input placeholder="渲染输入框" {...model.default} style={{ width: size.width + 'px' }}></Input>,
    key: 'collapse',
    // model: {   //{default:'username'}
    //     default: '绑定字段'

    // }

})






registerConfig.register({
    label: ' ',
    preview: () => <Button
        type="primary"
        icon={<QrcodeOutlined />}
    >
        二维码
    </Button>,
    render: () => <QRCode
        bordered="false"
        type="svg"
        size='90'
        iconSize="15"
        errorLevel="H"
        value="http://www.ddspp.com"
        icon="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
    />,
    // render: ({ model, size }) => <Input placeholder="渲染输入框" {...model.default} style={{ width: size.width + 'px' }}></Input>,
    key: 'qrcode',
    // model: {   //{default:'username'}
    //     default: '绑定字段'

    // }

})


registerConfig.register({
    label: ' ',
    preview: () => <Button
        type="primary"
        icon={<DashOutlined />}
    >
        走马灯
    </Button>,
    render: () => <Carousel autoplay>

        <Image preview={false}
            width={"100%"}
            src={`http://www.ddspp.com/v3/images/ba05.png`} />

        <Image preview={false}
            width={"100%"}
            src={`http://www.ddspp.com/v3/images/ba02.png`} />

        {/* <div>
      <h3 >3</h3>
    </div>
    <div>
      <h3 >4</h3>
    </div> */}
    </Carousel>,
    // render: ({ model, size }) => <Input placeholder="渲染输入框" {...model.default} style={{ width: size.width + 'px' }}></Input>,
    key: 'pmd',
    // model: {   //{default:'username'}
    //     default: '绑定字段'

    // }

})




registerConfig.register({
    label: ' ',
    preview: () => <Button
    
    type="primary"
    icon={<TableOutlined />}
    block
>
    表格
</Button>,
    render: () => <Table 
    style={{with:"500px"}}
    columns={[{
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
      }]} dataSource={[
        {
          key: '1',        
          age: 32,
          address: 'New York No. 1 Lake Park',      
        },
        {
          key: '2',        
          age: 42,
          address: 'London No. 1 Lake Park',
        },
        {
          key: '3',
          age: 32,
          address: 'Sydney No. 1 Lake Park',
        },
      ]} />,
    // render: ({ model, size }) => <Input placeholder="渲染输入框" {...model.default} style={{ width: size.width + 'px' }}></Input>,
    key: 'table',
    // model: {   //{default:'username'}
    //     default: '绑定字段'

    // }
})




























