
import React  from 'react';
import {  Descriptions, ConfigProvider} from 'antd';


const items = [
  {
    key: '1',
    label: '用户名',
    children: '13733826017',
  },
  {
    key: '2',
    label: '数据剩余流量',
    children: '8000005',
  },
  {
    key: '3',
    label: '余额',
    children: '0.00',
  },
  {
    key: '4',
    label: '企业主体',
    children: '暂无信息 ',
  },
  {
    key: '5',
    label: '认证周期',
    span: 2,
    children: ' 暂无信息',
  },
  {
    key: '6',
    label: '分销网址',
    span: 3,
    children: (
      <>
        Data disk type: MongoDB
        <br />
        Database version: 3.4
        
      </>
    ),
  },



];








const Mp0 = () => {



  
 



  return <ConfigProvider
    theme={{
      token: {
        colorFillAlter: '#e6f4ff',
      },
    }}
  >

    <Descriptions  layout="vertical" bordered items={items} />
    


    
    




  </ConfigProvider>

}
export default Mp0