//判断有无内测资格，Y 有   N  无 如果为N 则跳转至无权限提示页面
import { request } from "@/utils"
import {useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
export function AuthAlpha () {
const navigate = useNavigate()
    const bjztq = { smsg1: 'N', smsg2: 'Y' }
    useEffect(() => {
        async function getau() {
            const resg = await request.get('/authalpha')
            //const bjzt = resg.return_code
            const bjzt = resg.alpha
            //alpha
            if (bjzt === bjztq.smsg1) {
                return navigate('/m/no_access')
            }
        }
        getau()
    }, [bjztq.smsg1, bjztq.smsg2, bjztq.success, navigate])

    
   
}