import React, { useEffect, useState } from 'react';
import { Select, Button, Form, Input, ConfigProvider } from 'antd';
import { request } from '@/utils'
import zhCN from 'antd/locale/zh_CN';




const M4z1 = () => {
    const [value, setValue] = useState([]);

    const [bangm, setbangm] = useState([]);
    useEffect(() => {
        async function getau() {
            const res = await request.post('/p_bank')
            setbangm(res.rescode)
        }

        getau()

    }, [])

    const onFinish = (value) => {
        console.log(value);
    };
    return <ConfigProvider locale={zhCN}>
        <>
            <Form
                name="wrap"
                labelCol={{
                    flex: '110px',
                }}
                labelAlign="left"
                labelWrap
                wrapperCol={{
                    flex: 1,
                }}
                colon={false}
                style={{
                    maxWidth: 600,
                }}
                onFinish={onFinish}
            >
                 M4z1
                <Form.Item
                    label="企业名称"
                    name="qname"
                    rules={[
                        {
                            required: true, message: '请填写正确公司名称！'
                        },
                    ]}
                >
                    <Input />  
                </Form.Item>
                <Form.Item
                    label="统一信用代码"
                    name="xydm"
                    rules={[
                        {
                            required: true, message: '请填写公司统一信用代码！'
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
               
                <Form.Item
                    label="法人名称"
                    name="fname"
                    rules={[
                        {
                            required: true, message: '请填写法人名字！'
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="对公账号"
                    name="gnum"
                    rules={[
                        {
                            required: true, message: '请填写正确的公司账户！'
                        },
                    ]}
                >
                    <Input style={{
                        width: '100%',
                        alignContent: 'left'
                    }}
                    />
                </Form.Item>

                <Form.Item
                    label="开户行"
                    name="bankname"
                    rules={[
                        {
                            required: true, message: '请选择公司账户开户行！'
                        },
                    ]}
                >

                    <Select
                        //  mode="tags"
                        showSearch


                        style={{
                            width: '100%', alignItems: 'left'
                        }}
                        onChange={setValue}
                        value={value}

                        options={bangm.map((e) => ({
                            label: e.bankname,
                            value: e.bankname
                        }
                        ))
                        }
                    />
                </Form.Item>
                <Form.Item label=" ">
                    <Button type="primary" block={true} htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
      

        </>

    </ConfigProvider>


}

export default M4z1