import { message,ConfigProvider  } from 'antd';
import { request,removeToken } from "@/utils"
import { useEffect } from "react"
import Leftm from "@/pages/Leftm"
import { useNavigate } from 'react-router-dom';
import zhCN from 'antd/locale/zh_CN';



const Layout = () => {
    const navigate = useNavigate()
    const bjztq = { smsg1: 'success', smsg2: 'false' }
    useEffect(() => {
        async function getau() {
            const resg = await request.get('/authorization')
            const bjzt = resg.return_code
            if (bjzt === bjztq.smsg2) {
                message.error('登录超时')
                removeToken()
                return navigate('/login')
            }

        }
        getau()
    }, [bjztq.smsg2, bjztq.success, navigate])
    
    return <ConfigProvider locale={zhCN}><Leftm></Leftm></ConfigProvider>

}

export default Layout