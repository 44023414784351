
import React from 'react';
import { ConfigProvider,QRCode} from 'antd';
import { useSearchParams } from 'react-router-dom';







const Pqr = () => {


  const [urlp] = useSearchParams()
  const code=urlp.get('code')
  const icon=urlp.get('icon')
  

  return <ConfigProvider
    theme={{
      token: {
        colorFillAlter: '#e6f4ff',
      },
    }}
  >
<QRCode type="svg" value={code}  icon={icon}/>

  </ConfigProvider>

}
export default Pqr