/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Table, ConfigProvider, Button, Space, Tooltip, Radio, Modal, Mentions, Spin, message } from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { request } from '@/utils'
//  import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import './index.scss'
import zhCN from 'antd/locale/zh_CN';



// const plainOptions = ['需要', '不需要'];
const optionsWithDisabled = [
  {
    label: '需要',
    value: 'Y',
  },
  {
    label: '不需要',
    value: 'N',
  }
];

const M2d4 = () => {
  const [tdown] = Form.useForm();
  const [spinning, setSpinning] = useState(false);


  const [open, setOpen] = useState(false);
  const [bangm, setbangm] = useState([]);



  const toDown = async (e) => { // 数据下单
    setSpinning(true)
    setOpen(false)
    const dbd = await request.post('/p_dbdown', e)
    if (dbd.return_code) { setSpinning(false) }
    if ((e.email === "" | e.email === undefined) && dbd.return_code === "success") { window.open(dbd.turl, "_blank") }   //没填写邮箱,直接返回下载链接
    if ((e.email === "" | e.email === undefined) && dbd.return_code === "F") { message.error(dbd.return_msg) }  //没填写邮箱,直接返回下载链接

    if ((e.email !== "" && e.email !== undefined) && dbd.return_code === "F") { message.error(dbd.return_msg) } //填写邮箱,返回处理结果
    if ((e.email !== "" && e.email !== undefined) && dbd.return_code === "success") { message.success(dbd.return_msg) } //填写邮箱,返回处理结果

  }


  useEffect(() => {
    async function getau() {
      const res = await request.post('/pdb_list')
      setbangm(res.rescode)
    }

    getau()

  }, [])










  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`检索内容`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            检索
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            重置
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            关闭
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const ppth = (e) => {
  setOpen(true)
    tdown.setFieldsValue({ dbno: e.utno, ddno: e.key, cpname: e.pname })

  }


  const columns = [

    {
      title: '数据批号',
      dataIndex: 'key',
      key: 'key',
      sorter: (a, b) => a.key - b.key,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '数据类型',
      dataIndex: 'dblx',
      key: 'dblx',
    },
    {
      title: '产品名称',
      key: 'pname',
      ...getColumnSearchProps('pname'),
      dataIndex: 'pname',


    },
    {
      title: '建档时间',
      dataIndex: 'dtime',
      key: 'dtime',
      ...getColumnSearchProps('dtime'),
    },
    {
      title: '数量',
      dataIndex: 'db_total',
      key: 'db_total',
    },
    {
      title: "",
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="数据下载" ><Button type="primary" icon={<DownloadOutlined />}
            // id={record.key} 
            onClick={() => ppth(record)}>数据下载</Button></Tooltip>
        </Space>
      ),
    },
  ];














  const [form] = Form.useForm();
  return (
    <>


      <ConfigProvider locale={zhCN} >




        <Form form={form} component={false}>

          <Table
            // rowSelection={   {type:"checkbox",...rowSelection,}} 
            columns={columns} dataSource={bangm} />

        </Form>



        <Modal
          title="数据信息"
          centered
          open={open}
          footer={false}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
        >
          <Form form={tdown} onFinish={toDown}>



            <Form.Item hidden name="dbno"><Input /></Form.Item>

            <Form.Item label="数据批号" name="ddno">
              <Input disabled /></Form.Item>

            <Form.Item label="产品名称" name="cpname"
            >
              <Input disabled />


            </Form.Item>
            <Form.Item label="接收邮箱" name="email"
            >
              <Mentions
                style={{
                  width: '100%',
                }}
                split=''
                options={[
                  { value: 'qq.com', label: 'qq.com', },
                  { value: '163.com', label: '163.com', },
                  { value: '126.com', label: '126.com', },
                  { value: 'sina.com', label: 'sina.com', },
                  { value: 'yeah.net', label: 'yeah.net', },
                  { value: '188.com', label: '188.com', },
                  { value: 'vip.163.com', label: 'vip.163.com', },
                  { value: 'vip.126.com', label: 'vip.126.com', },

                ]}
              />


            </Form.Item>


            <Space.Compact>
              <Space>

                <Form.Item label="数据序号" name="dblis"
                >
                  <Radio.Group
                    options={optionsWithDisabled}
                    optionType="button"
                    buttonStyle="solid"
                  />


                </Form.Item>

                <Form.Item label="验证码：" name="yzcode"
                >
                  <Radio.Group
                    options={optionsWithDisabled}
                    optionType="button"
                    buttonStyle="solid"
                  />


                </Form.Item>
              </Space>




            </Space.Compact>














            <Form.Item >
              <Button type="primary" htmlType="submit">
                提 交
              </Button>
            </Form.Item>

          </Form>




        </Modal>





        <Spin spinning={spinning} tip="数据整理中，请稍等..." fullscreen />

      </ConfigProvider>
    </>

  );
};
export default M2d4;