import axios from "axios";
import { getToken } from "./token";

const request = axios.create({
    baseURL: 'http://api.ddspp.com:30123/v3',   
    timeout: 0
})
//添加请求拦截器 在请求前插入一些自定义配置
request.interceptors.request.use((config)=>{
    const token=getToken()
    if(token){
        config.headers.Authorization=`{"Bearer_ddspp":"${token}"}`
    }
    return config
},(error)=>{
    return Promise.reject(error)
})
//添加响应拦截器  在响应返回到客户端之前 做拦截 重点处理返回的数据
request.interceptors.response.use((response)=>{
    //return response.data
    return response.data
    
},(error)=>{
    return Promise.reject(error)
})


export { request }