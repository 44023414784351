import { Menu, Space, Image } from 'antd';
import React, { useState } from 'react';
import { AppstoreOutlined, HomeOutlined, PropertySafetyOutlined, 
     TeamOutlined,LinkOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './index.scss';

const Fhead = () => {
    const navigate = useNavigate()
    const items = [
        {
            label: '首页',
            key: '/',
            icon: <HomeOutlined />,
        },
        {
            label: '产品中心',
            key: '/menu1',
            icon: <AppstoreOutlined />,

        },
        {
            label: <a target='blank' href='http://www.ddspp.com/v2/' >农资普惠版</a>,
            key: '/#',
            icon: <LinkOutlined />,

        },

    
        {
            label: '价格公示',
            key: '/menu2',
            icon: <PropertySafetyOutlined />,

        },


        // {
        //     label: '操作教程',
        //     key: '/menu3',
        //     icon: <VideoCameraAddOutlined />,

        // },
        {
            label: '合作计划',
            key: '/menu4',
            icon: <TeamOutlined />,

        },




        {
            key: '/login',
            label: (
                <font >
                    登录
                </font>
            ),
        },
        {
            key: '/reg',
            label: (
                <font >
                    注册
                </font>
            ),
        },
    ];






    const [current, setCurrent] = useState('mail');
    const onClickm = (e) => {
        setCurrent(e.key);
        navigate(e.key, { replace: true })

    };
    const url = 'http://www.ddspp.com/api/img/v3_logo_m.png';

    const muclass = { background: "transparent" }
     const fhead = { background: "#f0f5ff" }

    return <><div className='fhead' style={fhead}>
        <Space >
            <Image
                width={218}
                src={url}
                alt="avatar"
                preview={false}
                className='himg'
            />

            <Menu style={muclass} onClick={onClickm} selectedKeys={[current]} mode="horizontal" items={items} className='hmenu' />
        </Space>





        <hr />




    </div>


    </>


}

export default Fhead