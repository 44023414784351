import './index.scss'
import { Conf } from "../../index";
import React,{ useContext} from 'react';
import { Button } from 'antd-mobile'
import datajson from './data.json';
//////////////////////////
const Mp1 = () => {
   



    const con1 = useContext(Conf);
    
    const datajs = datajson;
   
    
    const containerStyles={
        width: datajs.container.width + 'px',
        height: datajs.container.height + 'px'
    }
    // console.log("Conf", datajs);
    // const containerRef=React.createRef(null);
    //const { dragstart, dragend } = useMenuDragger(containerRef) ;//实现菜单的拖转
    const dragstart=(e,compoent)=>{
    
        console.log(compoent)
 
     }
    return <><div className="editor">
        <div className="editor-left">
            <Button></Button>
            {/* 根据注册列表，渲染对应内容 可以实现H5的拖拽 */}
            {con1.componentList.map(compoent=>(
            <div className='editor-left-item' 
            draggable
            onDragStart={e=> dragstart(e,compoent)}
            //onDragEnd={dragend}

            key={compoent.key}>
                <span>{compoent.label}</span>
                <span>{compoent.preview()}</span>
            </div>
        ))}





        </div>
        <div className="editor-top">

        </div>
        <div className="editor-right">


        </div>
        <div className="editor-container">
            {/* 负责产生滚动条 */}
            <div className="editor-container-canvas">
                {/* 产生内容区域 */}
                <div className="editor-container-canvas-content"
                style={containerStyles}
                // ref={containerRef}
                >



                    {
                        // 
                        

                        (datajs.blocks.map((a) => (
                        
                        
                        <div draggable
                         className="editor-block" key={a.key} 
                        style={{top:a.top+'px',left:a.left+"px"}}
                        
                        >
                            

                            {
                                
                                con1.componentMap[a.key].render()
                                
                            }

                        </div>
                        )

                        )
                        )
                    }




                </div>


            </div>
        </div>

    </div>



    </>
}
export default Mp1