import React, { useState,useEffect } from 'react';
import { Form, Table, ConfigProvider} from 'antd';
import { request } from '@/utils'
import './index.scss'
import zhCN from 'antd/locale/zh_CN';





const M3c2 = () => {
  
  
   const [bangm, setbangm] = useState([]);
  useEffect(() => {
    async function getau() {
      const res = await request.post('/p_paylist',{dow:"XF"})
       setbangm(res.rescode)
    }
  
    getau()
  
  }, [])
  

 



 
 const columns = [
  
  {
    title: '订单编号',
    dataIndex: 'ddno',
    key: 'ddno',
  },
  {
    title: '金额',
    dataIndex: 'samount',
    key: 'samount',
  },
  {
    title: '产品编号',
    dataIndex: 'p_w',
    key: 'p_w',
  },
  {
    title: '开票状态',
    dataIndex: 'pay_time',
    key: 'pay_time',
  }
];
  

  

 









  const [form] = Form.useForm();
  return (<ConfigProvider locale={zhCN} >

    <div 
    // style={containerStyle} 
    >

      <Form form={form} component={false}>

      <Table  columns={columns} dataSource={bangm} />

      </Form>


      
    </div>

    
  </ConfigProvider>

  );
};
export default M3c2;