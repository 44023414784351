import { Button, Form, Input, Card,Space } from 'antd';
import { useDispatch } from 'react-redux';
import { fetchLogin } from '@/store/modules/user';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import Ffoot from '@/pages/Ffoot';
import Fhead from '@/pages/Fhead';
import './index.scss'


const Login = () => {

 
  





  const dispatch = useDispatch()
  const navigate = useNavigate()
  const onFinish = async (values) => {
    await dispatch(fetchLogin(values))
    navigate('/m')
  }




  return <>
 
    <Fhead />
    <div className='fbody'>
      <div className='fbodym' >

        <Card
          hoverable
          style={{
            width: 540,
            margin: '100px auto'
          }}

        >





          <Form onFinish={onFinish}
            validateTrigger="onBlur"
          >

<Form.Item
              name="utitle"
            >
              <Space
                direction="vertical"
                size="middle"
                style={{
                  fontSize: '1.5em',
                  fontWeight:'bold'
                }}
              >用户登录</Space>
            </Form.Item>

            <Form.Item
              name="username"
              rules={[
                { required: true, message: '请输入用户名!' },
                { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号!' }

              ]}
            >
              <Input placeholder="请输入用户名!" autoComplete="true" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: '请输入密码!' }]}
            >
              <Input.Password

                type="password"
                placeholder="请输入密码!"
                autoComplete="true"
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block={true} >
                登   录
              </Button>


            </Form.Item>
          </Form>
        </Card>

      </div>
    </div>

    <Ffoot />


  </>

}
export default Login