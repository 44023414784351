import React, { useEffect, useState } from 'react';
import {
  UserOutlined,
  SettingOutlined, PoweroffOutlined,
  HomeOutlined, PropertySafetyOutlined, QrcodeOutlined, TeamOutlined, GiftOutlined,
  VerifiedOutlined, AlertOutlined, AuditOutlined, CarryOutOutlined, ToolOutlined, ScheduleOutlined, RestOutlined, DatabaseOutlined,
  //  SafetyCertificateOutlined, 
   EnvironmentOutlined, LaptopOutlined, CalendarOutlined, BookOutlined
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, Image, Button, Space, Flex, Popconfirm, Drawer, Descriptions, Input, Form, QRCode, Result, Radio, message,Typography } from 'antd';
import { Outlet, useNavigate} from 'react-router-dom';

import { request } from '@/utils'
import md5 from "js-md5";
import './index.scss'

const { Paragraph } = Typography;
const { Header, Content, Sider } = Layout;
const url = 'http://www.ddspp.com/api/img/v3_logo_m.png';
const boxStyle = {
  width: '100%',

};

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}







const Leftm = () => {






  const [bangm, setbangm] = useState([]);
  const [sysz, setSys] = useState([]);

  useEffect(() => {
    async function getau() {
      const res = await request.post('/authalpha')
      setbangm(res)
      res.u_zqjson && setSys(res.u_zqjson.sys)

    }

    getau()

  }, [])

  const items = [
    {
      label: '账号',
      children: bangm.u_name,
    },
    {
      label: '联系电话',
      children: bangm.u_phone,
    },
    {
      label: '钱包余额',
      children: <span>￥{bangm.u_qb*10/10}</span>,
    },
    {
      label: '推广链接',
      children: <Paragraph copyable>{'http://ddspp.vip/?t='+bangm.uid}</Paragraph>,
    },


  ];



  //Link
  const [pdno, setPdno] = React.useState();//订单号码
  const [zurl1, setZurl1] = React.useState();//二维码地址内容
  const [zicon, setZicon] = React.useState();//码眼链接
  const [pcode, setpcode] = React.useState(false);//显示二维码图片开关
  const [pzt, setpzt] = React.useState(false);//支付状态
  const [pc, setpc] = React.useState(false);//查询支付结果  轮询状态

  const [paym] = Form.useForm();
  const payje = { payje: Form.useWatch('payje', paym) };


  const xzpay = (c) => {
    const payto = { payto: c.target.value };



    const vxdz = Object.assign(payje, payto);
    if (payje.payje === undefined || payje.payje === '' || payje.payje <= 0) { message.error('请填写正确金额') } else { Tpay(vxdz, pc) }
  };



  const Tpay = async (props, zicon, zurl1, pcode, pzt, pc, pdno) => {
    pc = true
    const respaym = await request.post('/p_pay', props)
    respaym.return_code === 'success' && setpc(pc)
    pdno = { ddno: respaym.ddno }
    setPdno(pdno)
    zurl1 = respaym.zurl
    zicon = respaym.icon
    pcode = true

    pc && setpcode(pcode)

    setZurl1(zurl1)
    setZicon(zicon)
    
    setpcode(pcode)
    setpzt(pzt)



  }

  const onFinishmm = async (values) => {

    const md5psw = md5(values['password']);
    const md5psw2 = md5(values['password1']);
    const md5psw3 = md5(values['password2']);
    const p00 = { password: md5psw };
    const p01 = { password1: md5psw2 };
    const p02 = { password2: md5psw3 };
    const values1 = Object.assign(values, p00);
    const values2 = Object.assign(values1, p01);
    const values3 = Object.assign(values2, p02);
    const res = await request.post('/ch_psw', values3)
    if (res.return_code === "success") {
      localStorage.removeItem('scusername')
      localStorage.removeItem('token_key')
      message.success(res.return_msg)
      navigate('/')
    }
    if (res.return_code === "F") {
      message.error(res.return_msg)
    }

  };






  const items2 = [

    getItem('首页', '', <HomeOutlined />),

    getItem('基础数据', 'M2', <DatabaseOutlined />, [
      getItem('通用信息', 'M21', <ScheduleOutlined />, [
        getItem('企业介绍', 'M2d1', <LaptopOutlined />),
        // getItem('通讯资料', 'M2d2', <LaptopOutlined />)
      ]
      ),

      getItem('产品建档', 'M2d3', <LaptopOutlined />),
      getItem('数据下载中心', 'M2d4', <QrcodeOutlined />),
      getItem('回收站', 'M2d5', <RestOutlined />),
    ]),
    getItem('财务账单', 'M3', <PropertySafetyOutlined />, [
      getItem('充值记录', 'M3c1', <CalendarOutlined />),
      getItem('消费记录', 'M3c2', <CalendarOutlined />),
      getItem('开票记录', 'M3c3', <CalendarOutlined />),
    ]),
    getItem('营销管理', 'M5', <GiftOutlined />, [
      getItem('活动管理', 'M5y1', <LaptopOutlined />),
      getItem('营销数据', 'M5y2', <BookOutlined />),
      getItem('奖励承兑', 'M5y3', <BookOutlined />),
    ]),

    getItem('账号中心', 'M4', <SettingOutlined />, [
      // getItem('账户安全', 'M4z1', <SafetyCertificateOutlined />),
      getItem('实名认证', 'M4z2', <VerifiedOutlined />),
      getItem('地址和联系人', 'M4z3', <EnvironmentOutlined />),
      getItem('员工账号', 'M4z4', <TeamOutlined />)

    ]),
    sysz === 'Y' &&  getItem('系统管理员', 'System', <SettingOutlined />, [getItem('产品中心', 'system01', null),getItem('实名认证', 'system02', <VerifiedOutlined />),getItem('地址和联系人', 'system03', <EnvironmentOutlined />),
    getItem('渠道管理', 'system04', <TeamOutlined />)]),

    (bangm.alpha === 'Y' && getItem('调试区', 'M999', <ToolOutlined />, [
      getItem('测试一', 'mp1', <VerifiedOutlined />),
      getItem('测试1', 'test1'),
      getItem('测试2', 'test2'),
      getItem('测试3', 'test3'),
      getItem('测试4', 'test4'),
      getItem('Option 5', 'mp3', <CarryOutOutlined />),
      getItem('Option 6', 'test', <CarryOutOutlined />),
      getItem('临时测试', 'M991', <ScheduleOutlined />, [
        getItem('tk', 'tk', <AlertOutlined />),
        getItem('test5', 'test5', <AuditOutlined />)
      ]
      ),
    ])
    )


  ];













  const navigate = useNavigate()
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const onMenuClick = (abs) => {
    const sedpath = abs.key
    navigate(sedpath)
  }


  //点击了退出 执行的 任务s
  const onConfirm = () => {
    localStorage.removeItem('scusername')
    localStorage.removeItem('token_key')
    navigate('/')
  }

  const [open, setOpen] = useState(false);
  const showLargeDrawer = () => {
    paym.setFieldsValue({ payje: '' });// 设置表单为空
    setOpen(true);
  };
  const onClose = () => {
    paym.setFieldsValue({ payje: '' });// 设置表单为空
    setPdno(undefined)
    setpc(false)
    setOpen(false);
    setZurl1(undefined)
  };
  const [openxm, setOpenxm] = useState(false);
  const xgmm = () => {

    setOpenxm(true);
  };
  const onClosexm = () => {
    setOpenxm(false);
  };




  useEffect(() => {
    if (pdno !== undefined) {
      const aat = setInterval(async () => {
        const cpz = await request.post('/p_payc', pdno) // 查支付状态
        if (cpz.ztcode === 'Y') {
          const u_qb={u_qb:Number(bangm.u_qb*10/10)+Number(cpz.amount*10/10)}
          const bangmn =Object.assign(bangm,u_qb)
          setbangm(bangmn)
          setpzt(true)
          setpcode(false)
          setPdno(undefined)

          setTimeout(() => {
            setpzt(false)
            setpc(false)
          }, 3000) //付款成功标志 




        }


      }, 5000)

      return () => { clearInterval(aat) }

    }
  }, [pdno,bangm])





  return (

    <Layout>
      <Header
        style={{
          display: 'flex',
          background: colorBgContainer
          // alignItems: 'center',
        }}
      >
        <Image
          width={258}
          src={url}
          alt="avatar"
          preview={false}
        />


        <Flex style={boxStyle} justify={'flex-end'} align={'center'}>
          <Space align="center">


            <span onClick={showLargeDrawer}><UserOutlined />{bangm.u_name}</span>
            <Button
              icon={<ToolOutlined />}
              onClick={xgmm}>修改密码
            </Button>

            <Popconfirm
              title="安全退出"
              okText="确认"
              cancelText="取消"
              onConfirm={onConfirm}

            >

              <Button
                icon={<PoweroffOutlined />}

              >退出
              </Button>
            </Popconfirm>

          </Space>

        </Flex>


        <Drawer
          title="账号信息"
          placement="right"
          size="large"
          onClose={onClose}
          className='zhxx'
          open={open}

        >
          <Descriptions items={items} />




          <Form
            name="paym"
            form={paym}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            autoComplete="off"

          >
            <Form.Item
              label="账号充值金额"
              name="payje"
              rules={[
                {
                  required: true,
                  message: '请填写正确的金额!',
                },
                { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/, message: '请填写正确的金额!' }
              ]}
            >
              <Input
                prefix="￥" suffix="RMB"
                autoComplete="true" />

            </Form.Item>


            <Form.Item
              label="支付方式"
              name="payto"
              className='payto'
              wrapperCol={{
                offset: 2,
                span: 16,
              }}
            >
              <Radio.Group onChange={payje === undefined ? '' : xzpay} >  
                <Radio value="WX" id='WX'>微信</Radio>
                <Radio value="ZFB" id='ZFB'>支付宝</Radio>

              </Radio.Group>
            </Form.Item>

            {pcode && <Form.Item className='payto'
              wrapperCol={{
                offset: 10,
                span: 16,
              }}
            >
              {zurl1 && <QRCode
                errorLevel="H"

                value={zurl1}
                icon={zicon}
              
              />}

            </Form.Item>}


          </Form>
          {pzt && <Result status="success" title="支付成功!" />}



        </Drawer>







        <Drawer
          title="修改密码"
          placement="right"
          onClose={onClosexm}
          className='zhxx'
          open={openxm}

        >
          <Form
            name="chmm"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinishmm}

          >
            <Form.Item
              name="password"
              rules={[{ required: true, message: '请输入密码!' }]}
            >
              <Input.Password

                type="password"
                placeholder="请输入密码!"
                autoComplete="true"
              />
            </Form.Item>

            <Form.Item
              name="password1"
              rules={[{ required: true, message: '请输入密码!' }]}
            >
              <Input.Password

                type="password"
                placeholder="请输入密码!"
                autoComplete="true"
              />
            </Form.Item>
            <Form.Item
              name="password2"
              rules={[{ required: true, message: '请输入密码!' }]}
            >
              <Input.Password

                type="password"
                placeholder="请输入密码!"
                autoComplete="true"
              />
            </Form.Item>



            <Form.Item
              name="payto"

            >
              <Button type="primary" htmlType="submit" block={true} >
                修    改    密    码
              </Button>
            </Form.Item>


          </Form>



        </Drawer>





      </Header>
      <Layout>
        <Sider
          width={200}
          style={{ background: colorBgContainer, }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            // selectedKeys={selectedkey}
            onClick={onMenuClick}

            style={{
              height: '100%',
              borderRight: 0,
              position: 'absolute'
            }}
            items={items2}
          />
        </Sider>
        <Layout
          style={{
            padding: '0 21px 21px',
          }}
        >
          <Breadcrumb
            style={{
              margin: '16px 0',
            }}
            items={[
              {
                title: '',
              }
            ]}
          >

          </Breadcrumb>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              height: 100 + 'vh',
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              overflow: 'auto'
            }}
            className='columnleft'
          >
            <Outlet></Outlet>
























          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default Leftm;