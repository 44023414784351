import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Space, Typography, ColorPicker } from 'antd';

const Test1 = () => {
    // const aa = { contents: [{ pp: "A", cc: "A2", namec: { metaColor: { "originalInput": { "h": 215.02145922746783, "s": 0.6175213675213675, "a": 1, "v": 0.7 }, "r": 68.27624999999998, "g": 114.16339055793989, "b": 178.5, "a": 1, "roundA": 1, "format": "hsv", "isValid": true } } }] }
    // console.log(aa.toHsbString())


    const onFinish = async (values) => {
        console.log(JSON.stringify(values))

    }



    const [form] = Form.useForm();

    // form.setFieldsValue(aa);

    const [value, setValue] = useState('#1677ff');

    return (




        <Form
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 18,
            }}
            onFinish={onFinish}
            form={form}
            //   name="dynamic_form_complex"
            style={{
                maxWidth: 1000,
            }}
            autoComplete="off"
            initialValues={{
                contents: [{}],
            }}
        >
            <Button type="primary"
                htmlType="submit"
                block={true} >
                注   册
            </Button>
            <Form.List name="contents" >



                {(fields, { add, remove }) => (


                    <div
                        style={{
                            display: 'flex',
                            rowGap: 16,
                            flexDirection: 'column',
                        }}
                    >
                        <Button type="dashed" onClick={() => add()} block>
                            + 添加模块
                        </Button>

                        {fields.map((field) => (
                            <Card
                                size="small"
                                title={`模块 ${field.name + 1}`}
                                key={field.key}
                                extra={
                                    <CloseOutlined
                                        onClick={() => {
                                            remove(field.name);
                                        }}
                                    />
                                }
                            >

                                <Form.Item label="p" name={[field.name, 'pp']} >
                                    <Input count={{ show: true, }} />
                                </Form.Item>
                                <Form.Item hidden={true} label="c" name={[field.name, 'cc']}  >
                                    <Input />
                                </Form.Item>

                                <Form.Item label=" wo" name={[field.name, 'namec']} >
                                    <ColorPicker 
                                    showText 
                                    // name={[field.name, 'ec']} 
                                        value={value}
                                        onChangeComplete={(c) => {

                                            setValue(c);
                                         console.log(c.toHexString())
                                         const ss = { contents: { [field.name]: { cc: c.toHexString() } } }
                                         form.setFieldsValue(ss)
                                        }}
                                    />
                                </Form.Item>

                                {/* Nest Form.List */}
                                <Form.Item label="List">
                                    <Form.List name={[field.name, 'list']}>
                                        {(subFields, subOpt) => (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    rowGap: 16,
                                                }}
                                            >
                                                {subFields.map((subField) => (
                                                    <Space key={subField.key}>
                                                        <Form.Item noStyle name={[subField.name, 'first']}>
                                                            <Input placeholder="类目名称" />
                                                        </Form.Item>
                                                        <Form.Item noStyle name={[subField.name, 'second']}>
                                                            <Input placeholder="内容" />
                                                        </Form.Item>
                                                        <CloseOutlined
                                                            onClick={() => {
                                                                subOpt.remove(subField.name);
                                                            }}
                                                        />
                                                    </Space>
                                                ))}
                                                <Button type="dashed" onClick={() => subOpt.add()} block>
                                                    + 添加子项目
                                                </Button>
                                            </div>
                                        )}
                                    </Form.List>
                                </Form.Item>
                            </Card>
                        ))}

                        {/* <Button type="dashed" onClick={() => add()} block>
                            + 添加模块
                        </Button> */}




                    </div>





                )}


            </Form.List>

            <Form.Item noStyle shouldUpdate>
                {() => (
                    <Typography>
                        <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
                        <pre>{JSON.stringify(form.getFieldsValue())}</pre>
                        {/* <span>HEX: {hexString}</span> */}
                    </Typography>
                )}
            </Form.Item>
        </Form>
    );
};
export default Test1