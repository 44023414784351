const TOKENKEY='scusername'

function setUserInfo(token){
    localStorage.setItem(TOKENKEY,token)
}
function getUserInfo(){
   return localStorage.getItem(TOKENKEY)
}

function removeUserInfo (){
    localStorage.removeItem(TOKENKEY)
}

export{
    setUserInfo,getUserInfo,removeUserInfo
}