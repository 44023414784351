//子目录登录鉴权
import { message } from 'antd';
import { request,removeToken } from "@/utils"
import {useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
export function Auth () {
const navigate = useNavigate()
    const bjztq = { smsg1: 'success', smsg2: 'false'}
    useEffect(() => {
        async function getau() {
            const resg = await request.get('/authorization')
            const bjzt = resg.return_code
        
   
            if (bjzt === bjztq.smsg2) {
                message.error('登录超时')
                removeToken()
                return navigate('/')
            }

          
        }
        getau()
    }, [bjztq.smsg2,bjztq.success, navigate])
    

    
   
}