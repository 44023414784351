// BjqLm
import React from 'react';
import {  Tabs } from 'antd';
const BjqLm = () => {
  
  return (
    <div>
      
   
      <Tabs
        defaultActiveKey="1"
        type="card"
        items={new Array(3).fill(null).map((_, i) => {
          const id = String(i + 1);
          return {
            label: `Card Tab ${id}`,
            key: id,
            children: `Content of card tab ${id}`,
          };
        })}
      />
    </div>
  );
};
export default BjqLm;