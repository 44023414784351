import React from 'react';
import { Empty } from 'antd';
import './index.scss';

import Ffoot from '@/pages/Ffoot';
import Fhead from '@/pages/Fhead';

const Dkf = () => {

 

 
  return <>
  <Fhead></Fhead>

    <div className='fbody'>
      <div className='fbodym'>
        <Empty></Empty>



        


        
      </div>
    </div>
    <Ffoot></Ffoot>
  </>


}

export default Dkf