import React, { useEffect, useState } from 'react';
import { CommentOutlined, FormatPainterOutlined } from '@ant-design/icons';
import {
  Card, List,
  Carousel, Image,
  FloatButton, QRCode, Input, Col, Row
} from 'antd';
import './index.scss';


const { TextArea } = Input;

const Fbody = () => {
  const [text, setText] = useState('http://www.ddspp.com/');
  useEffect(() => {
    //console.log('结果改变了：' + text)
  }, [text])

  const data = [
    {
      title: <><FormatPainterOutlined /> 印刷喷码注意事项</>, content: "辛苦绝对是，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克"
    },
    {
      title: <><FormatPainterOutlined /> 印刷喷码注意事项</>, content: "辛苦绝对是，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克"
    },
    {
      title: <><FormatPainterOutlined /> 印刷喷码注意事项</>, content: "辛苦绝对是，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克"
    },
  ];

  const data2 = [

    {
      title: '更新计划', content: "，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克"
    },
    {
      title: '行业信息', content: "辛苦绝对是，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克，萨卡手机卡刷卡机萨克"
    },
  ];

  const mtpl = { 'margin': "10px 10px", }

  return <>

    <div
      className='fbody'
    >
      <div
        className='fbodym'
      >




        <Carousel effect="fade" autoplay dotPosition='top' dots className='zmd'>

          <Image preview={false} width={'1200px'} 

            src="http://www.ddspp.com/api/img/v3_bant01.jpg" />
          <Image preview={false} width={'1200px'}

            src="http://www.ddspp.com/api/img/v3_bant02.jpg" />
          <Image preview={false} width={'1200px'}

            src="http://www.ddspp.com/api/img/v3_bant03.jpg" />

          <Image preview={false} width={'1200px'}

            src="http://www.ddspp.com/api/img/v3_bant04.jpg" />


        </Carousel>





        <Card
          style={mtpl}

        >

          <Row>
            <Col span={4}
              className='nrcenter'
            >
              <QRCode value={text ||
                'http://www.ddspp.com'}
              />
            </Col>
            <Col span={20} >
              <TextArea
                rows={7}
                placeholder="http://www.ddspp.com"
                maxLength={80}

                value={text}
                onChange={(e) => { setText(e.target.value) }
                }
                showCount
              />
            </Col>
          </Row>




        </Card>

        <List
          grid={{
            gutter: 16,
            column: 2,
          }}
          split={false}
          dataSource={data2}
          renderItem={(item) => (
            <List.Item>
              <Card title={item.title}>{item.content}</Card>
            </List.Item>
          )}
        />








        <List
          grid={{
            gutter: 16,
            column: 3,
          }}

          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Card title={item.title}>Card content{item.content}</Card>
            </List.Item>
          )}
        />



        <FloatButton
          icon={<CommentOutlined />}
          description="微信客服"
          shape="square"
          href="https://work.weixin.qq.com/kfid/kfcfeff9d9a4dfb1ce0"
          target="_blank"
          className='wxkf'
        />
      </div>
    </div>
  </>


}

export default Fbody