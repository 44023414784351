import React, { useState } from 'react';
import zhCN from 'antd/locale/zh_CN';
import { Input,DatePicker,ConfigProvider} from 'antd';
// import { registerConfig as config} from '@/utils/atk_config'

const { RangePicker } = DatePicker;
const Atk=()=>{
    const [invalue,upinvalue]= useState()

    const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    // const tooLate = dates[0] && current.diff(dates[0], 'days') >= 7;
    // const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 7;
    // return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };
  
   
   return <ConfigProvider locale={zhCN}><><Input 
   allowClear
   placeholder="Basic usage"
   prefix="￥" suffix="RMB" 
   onChange={e=>upinvalue(e.target.value)  }  
    value={invalue}/>

<RangePicker
      value={dates || value}
      disabledDate={disabledDate}
      onCalendarChange={(val) => {
        setDates(val);
      }}
      onChange={(val) => {
        setValue(val);
      }}
      onOpenChange={onOpenChange}
      changeOnBlur
    />

    {invalue}
    </>
    </ConfigProvider>
    }
    export default Atk