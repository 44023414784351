//System01
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { request } from '@/utils'
import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Divider } from 'antd';
import { Editor, Toolbar } from '@wangeditor/editor-for-react'


const System01=()=> {

    const [fm] = Form.useForm();

    const onFinish = async (values) => {
        await request.post('/p_cpjson', values)
        console.log(values);
    };



    // editor 实例



    const [editor, setEditor] = useState(null)                   // JS 语法

    // 编辑器内容
    const [html, setHtml] = useState('暂无信息')

    // 模拟 ajax 请求，异步设置 html



    useEffect(() => {
        async function getau() {
            const res = await request.get('/authalpha')
            setHtml(res.u_zqjson.cpjson)
        }
        getau()
    }, [])




    // 工具栏配置
    const toolbarConfig = {}                        // JS 语法

    // 编辑器配置
    const editorConfig = {                         // JS 语法
        placeholder: '请输入内容...',
    }

    // 及时销毁 editor ，重要！
    useEffect(() => {
        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)

        }
    }, [editor, setEditor])

    return (
        <>
            <div style={{ border: '1px solid #ccc', zIndex: 100 }}>
                <Toolbar
                    editor={editor}
                    defaultConfig={toolbarConfig}
                    mode="default"
                    style={{
                        borderBottom: '1px solid #ccc'
                    }}
                />
                <Editor
                    defaultConfig={editorConfig}
                    value={html}
                    onCreated={setEditor}
                    onChange={editor => {
                        setHtml(editor.getHtml())
                        fm.setFieldsValue({ cpjson: editor.getHtml() })

                    }

                    }
                    mode="default"
                    style={{ height: '500px', overflowY: 'hidden' }}
                />
            </div>

            <Divider />

            <Form
                name="fm"
                form={fm}
                onFinish={onFinish}
                colon={false}
            >
                <Form.Item
                    name="cpjson"
                    // hidden={true}
                >
                    <Input autoComplete="true"
                    />
                </Form.Item>

                <Form.Item label="">
                    <Button type="primary" htmlType="submit">
                        提 交
                    </Button>
                </Form.Item>
            </Form>


          

        </>
    )
}

export default System01