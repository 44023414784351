import React, { useState, useEffect, useMemo } from 'react';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Space, ColorPicker, Image, Upload, Divider, message } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { request } from '@/utils'





const M2d6 = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const token = localStorage.getItem('token_key')




    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);

    // const colornr='#1677ff';
    const [urlp] = useSearchParams()
    const [form] = Form.useForm();

    const [color, setColor] = useState('#1677ff');
    const bgColor = useMemo(() => {
        return typeof color === 'string' ? color : color.toHexString()
    }, [color]);
    const btnStyle = {
        backgroundColor: bgColor,
    };


    useEffect(() => {
        async function getau() {
            const res = await request.post('/pms_style1_c', { pno: urlp.get('q') })
            // console.log(res.json.mcv)
            if (res.json !== undefined) {
                if (res.json.mcv !== undefined) {
                    setColor(res.json.mcv)


                }
            }
            res.json && res.json.imgjson && setFileList(res.json.imgjson)
            form.setFieldsValue(res.json)//设置默认颜色 && pno
            form.setFieldsValue({ pno: urlp.get('q') })

        }

        getau()

    }, [urlp, form])
    //更新颜色
    useEffect(() => { form.setFieldsValue({ mcv: typeof color === 'string' ? color : color.toHexString() })}, [color, form])


    const onFinish = async (values) => {
        const resc = await request.post('/pms_style1_u', values)
        messageApi.open({
            type: resc.return_code,
            content: resc.return_msg,
        });

    }


    const onRemove = async (e) => {
        await request.post('/p_upfile_d', e)
        form.setFieldsValue({ imgjson: fileList })// 更新imgjson

    }


    const handleChange = ({ fileList: newFileList }) => {

        if (newFileList[0] !== undefined && newFileList[0].status === 'done') {

            newFileList = newFileList.map((file) => {


                if (file.response) {
                    if (file.response.return_code === 'success') {
                        file.uid = file.response.fbody[0].uid;
                        file.name = file.response.fbody[0].name;
                        file.status = file.response.fbody[0].status;
                        file.url = file.response.fbody[0].url;

                        //response
                    }
                    if (file.response.return_code === 'F') { file.status = "" }
                    //这里可以更新
                }
                delete file.response;
                delete file.originFileObj;
                delete file.lastModified;
                delete file.lastModifiedDate;
                delete file.percent;
                delete file.size;
                delete file.xhr;
                return file;
            });
        }

        setFileList(newFileList)
        form.setFieldsValue({ imgjson: newFileList })
    }
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const props = {
        action: 'http://api.ddspp.com:30123/v3/p_upfile',
        onChange: handleChange,
        fileList: fileList,
        showUploadList: true,
        listType: 'picture-card',
        headers: { Authorization: `{"Bearer_ddspp":"${token}"}` },
        onRemove: onRemove
    };




    return (<>{contextHolder}

        <Form
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 18,
            }}
            onFinish={onFinish}
            form={form}

            style={{
                maxWidth: 1000,
            }}
            autoComplete="off"
            initialValues={{
                mjson: [{}],
            }}
        >

            {/* <Form.Item label="产品图片" > */}
            <Upload
                {...props}
            >
                {fileList.length >= 5 ? null : uploadButton}
            </Upload>
            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}

            {/* </Form.Item> */}




            <Form.Item
                hidden={true}
                name='imgjson'  >
                <Input />
            </Form.Item>
            <Form.Item
                hidden={true}
                name='mcv'  >
                <Input />
            </Form.Item>
            <Form.Item hidden={true} name='pno'  >
                <Input />
            </Form.Item>


            <Form.List name="mjson" >



                {(fields, { add, remove }) => (


                    <div
                        style={{
                            display: 'flex',
                            rowGap: 16,
                            flexDirection: 'column',
                        }}
                    >

                        {/* <Form.Item label="主题颜色" > */}
                        <Space>

                            <ColorPicker
                                allowClear
                                showText
                                value={color} onChange={setColor}
                            // defaultValue={vcolz}
                            // onChangeComplete={(c) => {
                            //     const ss = { mcv: c.toHexString() }
                            //     form.setFieldsValue({ mcv: c.toHexString() })
                            //     console.log("颜：" + c.toHexString())
                            // }}
                            >
                                <Button type="primary"
                                    style={btnStyle}
                                >
                                    主体颜色
                                </Button> </ColorPicker>
                            <Button type="dashed" onClick={() => add()} >
                                + 添加模块
                            </Button>






                        </Space>

                        {/* </Form.Item> */}



                        {fields.map((field) => (
                            <Card
                                size="small"
                                title={`模块 ${field.name + 1}`}
                                key={field.key}
                                extra={
                                    <CloseOutlined
                                        onClick={() => {
                                            remove(field.name);
                                        }}
                                    />
                                }
                            >

                                <Form.Item label="模块名称" name={[field.name, 'mname']} >
                                    <Input count={{ show: true, }} />
                                </Form.Item>



                                {/* Nest Form.List */}
                                <Form.Item label="子项信息">
                                    <Form.List name={[field.name, 'list']}>
                                        {(subFields, subOpt) => (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    rowGap: 16,
                                                }}
                                            >
                                                {subFields.map((subField) => (
                                                    <Space key={subField.key}>
                                                        <Form.Item noStyle name={[subField.name, 'first']}>
                                                            <Input placeholder="类目名称" />
                                                        </Form.Item>
                                                        <Form.Item noStyle name={[subField.name, 'second']}>
                                                            <Input placeholder="内容" />
                                                        </Form.Item>
                                                        <CloseOutlined
                                                            onClick={() => {
                                                                subOpt.remove(subField.name);
                                                            }}
                                                        />
                                                    </Space>
                                                ))}
                                                <Button type="dashed" onClick={() => subOpt.add()} >
                                                    + 添加子项目
                                                </Button>
                                            </div>
                                        )}
                                    </Form.List>
                                </Form.Item>
                            </Card>
                        ))}






                    </div>





                )}


            </Form.List>
            <Divider />

            <Form.Item > <Button type="primary"
                htmlType="submit"
                block
            >
                提   交
            </Button>


            </Form.Item>







        </Form>
    </>

    );
};
export default M2d6