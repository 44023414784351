import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Fbody from '@/pages/Fbody';
import Ffoot from '@/pages/Ffoot';
import Fhead from '@/pages/Fhead';


import './index.scss';




const Am = () => {

    const navigate = useNavigate()
    const [tno] = useSearchParams()
    const tn = tno.get("t")
    useEffect(() => {
        if (tn !== null) {
            localStorage.setItem('promoter', tn)//把推广者Id写入 方便调用
            navigate('/') //隐藏推广链接
        }
    }, [navigate, tn])









    return <>
        <Fhead />

        <Fbody />

        <Ffoot />  {/* face foot */}

    </>

}
export default Am