import Layout from '@/pages/Layout'
import Am from '@/pages/Am'
import Reg from '@/pages/Reg'
import { createBrowserRouter } from 'react-router-dom'
import { AuthRoute } from '@/components/AuthRoute'
import { Auth } from '@/components/Auth'
import { AuthAlpha } from '@/components/AuthAlpha'
import Mp0 from '@/pages/Mp0'
import Mp1 from '@/pages/Mp1'
import Mp2 from '@/pages/Mp2'

import Pqr from '@/pages/Pqr'
import Atk from '@/pages/Atk'
import Test from '@/pages/Test'
import Test1 from '@/pages/Test1'
import Test2 from '@/pages/Test2'
import Test3 from '@/pages/Test3'
import Test4 from '@/pages/Test4'
import Test5 from '@/pages/Test5'
import Test6 from '@/pages/Test6'
import Test7 from '@/pages/Test7'

import BjqLm from '@/pages/BjqLm'
import Nqx from '@/pages/Nqx'
import NotFound from '@/pages/NotFound'
import Login from '@/pages/Login'
import Dkf from '@/pages/Dkf'
import Fbodym from '@/pages/Fbodym'
import Mcplis from '@/pages/Mcplis'



import M2d1 from '@/pages/M2d1'
import M2d2 from '@/pages/M2d2'
import M2d3 from '@/pages/M2d3'
import M2d4 from '@/pages/M2d4'

import M2d6 from '@/pages/M2d6'

import M3c1 from '@/pages/M3c1'
import M3c2 from '@/pages/M3c2'
import M3c3 from '@/pages/M3c3'

import M4z1 from '@/pages/M4z1'
import M4z2 from '@/pages/M4z2'

import M5y1 from '@/pages/M5y1'
import M5y2 from '@/pages/M5y2'
import M5y3 from '@/pages/M5y3'

import System01 from '@/pages/System01'
import System02 from '@/pages/System02'
import System03 from '@/pages/System03'
import System04 from '@/pages/System04'

const router = createBrowserRouter([
    {
        path: '/m',
        element: <AuthRoute> <Layout /> </AuthRoute>,
        children: [
             {path: '',element: <><Auth /> <Mcplis /></>},
            { path: 'M2d1', element: <><Auth /> <M2d1 /></> },
            { path: 'M2d2', element: <><Auth /> <M2d2 /></> },
            { path: 'M2d3', element: <><Auth /> <M2d3 /></> },
            { path: 'M2d4', element: <><Auth /> <M2d4 /></> },
            { path: 'M2d5', element: <><Auth /> <M2d3 /></> },
            { path: 'M2d6', element: <><Auth /> <M2d6 /></> },
            { path: 'M2d7', element: <><AuthAlpha /> <M2d3 /></> },

            { path: 'M3c1', element: <><Auth /> <M3c1 /> </> },
            { path: 'M3c2', element: <><Auth /> <M3c2 /> </> },
            { path: 'M3c3', element: <><Auth /> <M3c3 /> </> },

            { path: 'M4z1', element: <><Auth /> <M4z1 /></> },
            { path: 'M4z2', element: <><Auth /> <M4z2 /></> },
            { path: 'M4z3', element: <><Auth /> <Mp2 /></> },
            { path: 'M4z4', element: <><Auth /> <Mp2 /> </> },

            { path: 'M5y1', element: <><Auth /> <M5y1 /> </> },
            { path: 'M5y2', element: <><Auth /> <M5y2 /> </> },
            { path: 'M5y3', element: <><Auth /> <M5y3 /> </> },

            { path: 'system01', element: <><Auth /> <System01 /> </> },
            { path: 'system02', element: <><Auth /> <System02 /> </> },
            { path: 'system03', element: <><Auth /> <System03 /> </> },
            { path: 'system04', element: <><Auth /> <System04 /> </> },




            /////System01

            { path: 'index', element: <><Auth /><Mp0 /> </> },

            { path: 'mp2', element: <><Auth /><Mp2 /> </> },
            
            { path: 'tk', element: <><Auth /><Atk></Atk></> },
            { path: 'no_access', element: <><Auth /><Nqx></Nqx></> },

            { path: 'test', element: <><Auth /><Test></Test></> },
            { path: 'test1', element: <><Auth /><Test1></Test1></> },
            { path: 'test2', element: <><Auth /><Test2></Test2></> },
            {path: 'test3',element: <Test3 />},
            {path: 'test4',element: <Test4 />},
            { path: 'test5', element: <Test5/> },
            { path: 'test6', element: <Test6/> },
            { path: 'test7', element: <Test7/> },
            { path: 'mp1', element: <><Auth /><Mp1><BjqLm /></Mp1></> }

        ]
    },
    {path: '/',element: <Am />},
    {path: '/atest3',element: <Test3 />},
    {path: '/login',element: <Login />},
    { path: '/reg', element: <Reg />},
    { path: '/menu1', element: <Fbodym />},
    { path: '/menu2', element: <Fbodym />},
    { path: '/menu3', element: <Fbodym />},
    { path: '/menu4', element: <Dkf />},
    { path: '/qr', element: <Pqr />},
    {path: '*', element: <NotFound />}
])
export default router