import React, { useEffect, useState } from 'react';
import { Select, Button, Form, Input, ConfigProvider, Modal, Empty, Space, Popover, Tabs, Table, message, Tag, Alert, Descriptions, Divider } from 'antd';
import { request } from '@/utils'
import zhCN from 'antd/locale/zh_CN';


const content = (
    <><p>认证有效周期为一年，按照时间存在覆盖关系。</p>
        <p>企业认证为填写的对公户收款验证，自然人扫脸验证为支付宝人脸验证。</p></>


);

const M4z2 = () => {
    const [messageApi, contextHolder] = message.useMessage();

    const [dzyz] = Form.useForm();

    const [ims, setIms] = useState([]);
    const [open, setOpen] = useState(false);
    const [openz, setOpenz] = useState(false);
    const [openyz, setOpenyz] = useState(false);
    const [value, setValue] = useState([]);
    const [tabm, settabm] = useState([]);
    const [bangm, setbangm] = useState([]);

    const [ztv, setZtv] = useState(0);



    useEffect(() => {
        async function getau() {
            const res = await request.post('/p_bank')
            setbangm(res.rescode)
            const res2 = await request.post('/p_qiye_rzlog')
            settabm(res2.rescode)
        }

        getau()

    }, [ztv])

    const onFinish = async (value) => {
        const rzs = await request.post('/p_qiye_rz', value)
        if (rzs.return_code === 'success' && rzs.respCode === '00000000') {
            messageApi.open({
                type: 'success',
                content: '对公信息验证通过，请会计部门查看到账金额并在本系统进行核验！',
            })
            setOpen(false)

        } else {

            messageApi.open({
                type: 'error',
                content: rzs.return_code === "F" ? rzs.return_msg : rzs.respMsg
            })
            setOpen(false)
        }

    };
    // 验证
    const yzfinish = async (ea) => {
        const ryz = await request.post('/p_qiye_yz', ea)


        setZtv(ryz.ntime)
        if(ryz.return_code==='success'){
            messageApi.open({
                type: 'success',
                content: ryz.return_msg,
            })
            

        }else{
            messageApi.open({
                type: 'error',
                content:  ryz.return_msg ,
            })

        }
        setOpenyz(false)





        console.log(ryz);
    };

    //选中去验证
    const ppth = (e) => {
        setOpenyz(true)
        setIms([
            {
                key: '1',
                label: '企业名称',
                children: e.qname,
            },
            {
                key: '2',
                label: '收款账号',
                children: e.accountNo,
            },
            {
                key: '3',
                label: '开户行',
                children: e.accountBank,
            },
            {
                key: '4',
                label: '执行时间',
                children: e.dtime,
            }
        ])

        dzyz.setFieldsValue({ yzid: e.ddno,dzje:'' });
    }

    const columns = [

        {
            title: '发起时间',
            dataIndex: 'dtime',
            key: 'dtime',
        },
        {
            title: '企业名称',
            dataIndex: 'qname',
            key: 'qname',
        },
        {
            title: '收款账号',
            dataIndex: 'accountNo',
            key: 'accountNo',
        },
        {
            title: '开户行',
            dataIndex: 'accountBank',
            key: 'accountBank',
        },
        {
            title: '',
            dataIndex: 'accountBank',
            key: 'accountBank',
            render: (_, record) => (
                <Space size="middle">

                    {record.zt === 'N' && <Tag color="processing" onClick={() => ppth(record)}>待验证</Tag>}
                    {record.zt === 'F' && <Tag color="error">验证失败</Tag>}
                    {record.zt === 'Y' && <Tag color="success">验证成功</Tag>}
                </Space>
            ),
        }


    ];







    return <ConfigProvider locale={zhCN}>
        <>{contextHolder}

            <Popover content={content} title="认证说明" placement="right" >
                <Space>
                    <Button type="primary" onClick={() => { setOpen(true) }}>企业对公认证</Button>
                    <Button type="primary" onClick={() => setOpenz(true)} >自然人扫脸认证</Button>
                </Space>
            </Popover>

            <Modal
                title="企业认证信息"
                centered
                open={open}
                footer={null}

                onCancel={() => setOpen(false)}
                width={800}
            >


                <Form
                    name="wrap"
                    labelCol={{
                        flex: '110px',
                    }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{
                        flex: 1,
                    }}
                    colon={false}
                    style={{
                        maxWidth: 600,
                    }}
                    onFinish={onFinish}
                >

                    <Form.Item
                        label="企业名称"
                        name="qname"
                        rules={[
                            {
                                required: true, message: '请填写正确公司名称！'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="统一信用代码"
                        name="xydm"
                        rules={[
                            {
                                required: true, message: '请填写公司统一信用代码！'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="法人名称"
                        name="fname"
                        rules={[
                            {
                                required: true, message: '请填写法人名字！'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="对公账号"
                        name="gnum"
                        rules={[
                            {
                                required: true, message: '请填写正确的公司账户！'
                            },
                        ]}
                    >
                        <Input style={{
                            width: '100%',
                            alignContent: 'left'
                        }}
                        />
                    </Form.Item>

                    <Form.Item
                        label="开户行"
                        name="bankname"
                        rules={[
                            {
                                required: true, message: '请选择公司账户开户行！'
                            },
                        ]}
                    >

                        <Select
                            //  mode="tags"
                            showSearch


                            style={{
                                width: '100%', alignItems: 'left'
                            }}
                            onChange={setValue}
                            value={value}

                            options={bangm.map((e) => ({
                                label: e.bankname,
                                value: e.bankname
                            }
                            ))
                            }
                        />
                    </Form.Item>
                    <Form.Item label=" ">
                        <Button type="primary" block={true} htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>



            </Modal>



            <Modal
                title="自然人扫脸认证"
                centered
                open={openz}
                footer={null}

                onCancel={() => setOpenz(false)}
                width={800}
            >
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='此功能待上线' />

            </Modal>
            <Modal
                title='对公收款验证'
                centered
                open={openyz}
                footer={null}

                onCancel={() => setOpenyz(false)}
                width={800}
            >
                <Alert type="error" showIcon={false} message="请认真核对发起认证时间，查证对应时间段填写的对公账号收到的银联转账金额，正确填写，仅一次验证机会！" banner />

                <Divider ></Divider>
                <Descriptions items={ims} layout="vertical" bordered column={{
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 3,
                    xl: 4,
                    xxl: 4,
                }} />

                <Divider ></Divider>

                <Form
                    layout="inline"
                    form={dzyz}
                    onFinish={yzfinish}

                >

                    <Form.Item
                        name="yzid"
                        hidden

                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="dzje"
                        label='到账金额'
                        rules={[
                            {
                                required: true, message: '请填写到账金额！'
                            },
                        ]}
                    >
                        <Input placeholder="到账金额"
                            allowClear
                          

                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            验证
                        </Button>
                    </Form.Item>

                </Form>







            </Modal>





            <Tabs
                tabPosition='top'
                items={
                    [{
                        label: "企业对公认证",
                        key: 100,
                        children: <Table columns={columns} dataSource={tabm} />,

                    }, {
                        label: "个人认证",
                        key: 102,
                        children: `Content of Tab 个人认证`,

                    }
                    ]

                }
            />
















        </>

    </ConfigProvider>


}

export default M4z2