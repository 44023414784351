import React from 'react';
import { Space } from 'antd';
import './index.scss';

import Ffoot from '@/pages/Ffoot';
import Fhead from '@/pages/Fhead';





const Fbodym = () => {

    
    // const mtpl = { 'margin-top': "18px" }
  return <>
  <Fhead></Fhead>

    <div className='fbody'>
      <div className='fbodym'>
       
      <Space
      direction="vertical"
      style={{
        width: '100%',
      }}
      size={16}
    >
      
        <h4
          style={{
            marginBottom: 16,
          }}
        >
          Ant Design, a design language
        </h4>
        <p>
          We supply a series of design principles, practical patterns and high quality design
          resources (Sketch and Axure), to help people create their product prototypes beautifully
          and efficiently.
        </p>
      
      
    </Space>



        


        
      </div>
    </div>
    <Ffoot></Ffoot>
  </>


}

export default Fbodym