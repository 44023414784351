import React from 'react';
import { Result,Alert  } from 'antd';
import Marquee from 'react-fast-marquee';
const Nqx = () => (
  <Result
    // status="warning"
    status="403"
    title={
        <><Alert 
        // type="info" 
                  banner
                  message={<Marquee pauseOnHover gradient={false}>
                      此模块内测中，需要开通请咨询管理员！
                  </Marquee>} /></>
        
      }
    
  />
);
export default Nqx;