import React from 'react';
import { SmileOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { useSearchParams } from 'react-router-dom';
import './index.scss'

const M5y3 = () => {
  const [urlp, setSearch] = useSearchParams()
  console.log("urlc:" + urlp.get('p') + urlp)
  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api.open({
      message: 'Notification Title',
      description:
        'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
        className: 'columnleft',
      icon: (
        <SmileOutlined
          style={{
            color: '#108ee9',
          }}
        />
      ),
    });
  };
  return (
    <>
      {contextHolder}
      <Button type="primary" onClick={openNotification}>
        Open the notification box
      </Button><Button onClick={() => setSearch('p=004&title=哈哈&content=嘻嘻')}>
        点我更新一下search参数
      </Button>

    </>
  );
};

export default M5y3