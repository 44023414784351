import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { Provider } from 'react-redux';
import store from './store';
import { registerConfig as config } from './pages/Mp1/editor-config'
import 'normalize.css'

const config1 = config;
export const Conf = createContext({});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Conf.Provider value={config1} >

        <Provider store={store}>

            <RouterProvider router={router} />
        </Provider>


    </Conf.Provider>




);

