
import React from 'react';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Card ,Badge} from 'antd';
const { Meta } = Card;






const Mcplis = () => (
  
<>





aaaaaaaaaaaaaa{window.location.href } 



  <Card
    style={{
      width: 300,
    }}
    cover={
      <Badge.Ribbon text="按量计费">
  </Badge.Ribbon>
    }
    actions={[
      <SettingOutlined key="setting" />,
      <EditOutlined key="edit" />,
      <EllipsisOutlined key="ellipsis" />,
    ]}
  >
    


    
    <Meta
      avatar={ <Avatar
        style={{
          backgroundColor: '#7265e6',
          verticalAlign: 'middle',
        }}
        size="large"

      >
        券
      </Avatar>}
      title="数据包"
      description="This is the descriptionThis is the descriptionThis is the descriptionThis is the descriptionThis is the description"
    />
   
  </Card>

</>


);
export default Mcplis